function _createForOfIteratorHelper(o, allowArrayLike) {var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];if (!it) {if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {if (it) o = it;var i = 0;var F = function F() {};return { s: F, n: function n() {if (i >= o.length) return { done: true };return { done: false, value: o[i++] };}, e: function e(_e) {throw _e;}, f: F };}throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");}var normalCompletion = true,didErr = false,err;return { s: function s() {it = it.call(o);}, n: function n() {var step = it.next();normalCompletion = step.done;return step;}, e: function e(_e2) {didErr = true;err = _e2;}, f: function f() {try {if (!normalCompletion && it.return != null) it.return();} finally {if (didErr) throw err;}} };}function _unsupportedIterableToArray(o, minLen) {if (!o) return;if (typeof o === "string") return _arrayLikeToArray(o, minLen);var n = Object.prototype.toString.call(o).slice(8, -1);if (n === "Object" && o.constructor) n = o.constructor.name;if (n === "Map" || n === "Set") return Array.from(o);if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);}function _arrayLikeToArray(arr, len) {if (len == null || len > arr.length) len = arr.length;for (var i = 0, arr2 = new Array(len); i < len; i++) {arr2[i] = arr[i];}return arr2;}import { Prefs3D, Prefs2D } from './PreferenceNames';
import { LocalStorage } from './LocalStorage';

/**
 * Profiles encapsulate viewer settings, extensions to unload, and extensions to load.
 * 
 * The `profileSettings.settings` parameter will override the existing  {@link Autodesk.Viewing.Private.Preferences|preferences} upon calling the {@link Autodesk.Viewing.Profile#apply|apply} method.
 * The `profileSettings.extensions.load` and `profileSettings.extensions.unload` arrays are used to load and unload extensions.
 * Make sure to set the profile by using the {@link Autodesk.Viewing.Viewer3D#setProfile} method.
 * 
 * @example
 * const profileSettings = {
 *    name: "mySettings",
 *    description: "My personal settings.",
 *    settings: {
 *        ambientShadows: false,
 *        groundShadows: true
 *    }
 *    persistent: ['ambientShadows'],
 *    extensions: {
 *        load: ["Autodesk.BimWalk"],   // Extensions to load
 *        unload: ["Autodesk.ViewCubeUi"]  // Extensions to unload and to not load
 *    }
 * };
 * const profile = new Autodesk.Viewing.Profile(profileSettings);
 * @constructor

 * @param {ProfileSettings} profileSettings - the profile settings. 
 * @alias Autodesk.Viewing.Profile
 */
export function Profile(profileSettings) {
  if (!profileSettings) return;
  var av = Autodesk.Viewing;
  var parentProfileSettings = av.ProfileSettings.Default;
  var prefsToOverride = [];

  // Use "Custom" as the profile name if a name is not passed in with the settings object
  this.name = Object.prototype.hasOwnProperty.call(profileSettings, 'name') ? profileSettings.name : 'Custom';
  this.label = profileSettings.label;
  this.description = profileSettings.description;

  // The format version of the data stored locally.
  this.storageVersion = '2.0';

  // Check which preferences we want to store.
  this.persistent = Array.isArray(profileSettings.persistent) ?
  profileSettings.persistent :
  parentProfileSettings.persistent;

  // Assign the default profile
  this.settings = Object.assign({}, parentProfileSettings.settings);

  if (Object.prototype.hasOwnProperty.call(profileSettings, 'settings')) {
    var settings = profileSettings.settings;
    prefsToOverride = Object.keys(settings);
    // merge the passed in profile with the default profile
    this.settings = Object.assign(this.settings, settings);
  }

  var extsToLoad = [];
  var extsToUnload = [];

  // Get the extensions that need to be loaded and unloaded
  if (Object.prototype.hasOwnProperty.call(profileSettings, 'extensions')) {
    var toLoad = profileSettings.extensions.load;
    var toUnload = profileSettings.extensions.unload;
    extsToLoad = toLoad ? toLoad.slice() : extsToLoad;
    extsToUnload = toUnload ? toUnload.slice() : extsToUnload;
  }

  this.extensions = {
    load: extsToLoad,
    unload: extsToUnload };


  /**
   * Applies the profile's settings to the viewer preferences.
   * To make the viewer react to the updated preferences please reference {@link Autodesk.Viewing.Viewer3D#setProfile}.
   * @param {Autodesk.Viewing.Private.Preferences} prefs - preferences instance.
   * @param {boolean} [override=true] - Override all existing preferences with the profile's preferences. 
   * @alias Autodesk.Viewing.Profile#apply
   */
  this.apply = function (prefs) {var override = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    if (!prefs) {
      return false;
    }

    // If there are preferenes stored using the previous local store format, 
    // and they have the same name as the profile to be applied, those preferences
    // are moved to a JSON object under the profile name to apply.
    var currentStorageVerKey = prefs.getLocalStoragePrefix() + 'StorageVersion';
    var previousFormatProfileKey = 'Autodesk.Viewing.ProfileName';

    var needConversionFromVer1toVer2 = function needConversionFromVer1toVer2() {
      if (!prefs.useLocalStorage) {
        return false;
      }
      return !LocalStorage.getItem(currentStorageVerKey) &&
      !!LocalStorage.getItem(previousFormatProfileKey);
    };

    var convertStorageFromVer1toVer2 = function convertStorageFromVer1toVer2() {
      var profile = {};
      var profileName = LocalStorage.getItem(previousFormatProfileKey);
      var prefix = prefs.getLocalStoragePrefix();var _iterator = _createForOfIteratorHelper(
      LocalStorage.getAllKeys()),_step;try {for (_iterator.s(); !(_step = _iterator.n()).done;) {var key = _step.value;
          if (key.indexOf(prefix) !== -1) {
            try {
              profile[key.split('.').pop()] = JSON.parse(LocalStorage.getItem(key));
              LocalStorage.removeItem(key);
            }
            catch (_unused) {
              console.log("Cound't convert preference to new format: ".concat(key));
            }
          }
        }} catch (err) {_iterator.e(err);} finally {_iterator.f();}
      LocalStorage.setItem(prefix + profileName, JSON.stringify(profile));
      LocalStorage.removeItem(previousFormatProfileKey);
    };

    if (needConversionFromVer1toVer2()) {
      convertStorageFromVer1toVer2();
    }

    // Set the current storage format version.
    // If the format changes, knowing the format of saved data could be usefull
    // to convert the old data to the newer format.
    var currentSorageVerKey = prefs.getLocalStoragePrefix() + 'StorageVersion';
    LocalStorage.setItem(currentSorageVerKey, this.storageVersion);

    // Set the current profile in local storage.
    var currentProfileKey = prefs.getLocalStoragePrefix() + 'CurrentProfile';
    LocalStorage.setItem(currentProfileKey, this.name);

    prefs.setWebStorageKey(this.name);

    var settings = this.settings;
    var viewerDefined = [av.ProfileSettings.Default.name, av.ProfileSettings.AEC.name];
    var prefs3d = Object.values(Prefs3D);
    var prefs2d = Object.values(Prefs2D);
    for (var name in settings) {
      if (Object.prototype.hasOwnProperty.call(settings, name)) {
        var value = settings[name];
        // Ignore metadata if the profile is a custom one (not the ProfileSettings.AEC or the DefaultProfile Settings.)
        var tags =
        prefsToOverride.indexOf(name) !== -1 && viewerDefined.indexOf(this.name) === -1 ?
        ['ignore-producer'] :
        [];
        if (prefs3d.indexOf(name) !== -1) {
          tags.push('3d');
        } else if (prefs2d.indexOf(name) !== -1) {
          tags.push('2d');
        } else {
          tags.push('2d');
          tags.push('3d');
        }

        // If the preference is not in the persistent array then add the no-storage tag.
        if (this.persistent.indexOf(name) === -1) {
          tags.push('no-storage');
        }

        var storedValue = prefs.webStorage(name);
        var cachedValue = prefs.get(name);

        // If a value was stored, honor it no matter what.
        if (storedValue !== undefined) {
          if (storedValue != cachedValue) {
            prefs.add(name, storedValue, tags, true);
            prefs.setDefault(name, value); // Configuring default value and tags for reset functionality.
          }
          continue;
        }

        if (cachedValue !== undefined) {
          // Add tags to the preference even if the value did not change
          prefs.addTags(name, tags);
          // LMV-5591: override the set preferences with the profile's preferences.
          // Fire an event if the preference value is being changed by the profile
          if (cachedValue !== value && override) {
            prefs.set(name, value);
          }
          continue;
        }

        // Add the preference and fire the event.
        prefs.add(name, value, tags, true);
      }
    }

    return true;
  };
}