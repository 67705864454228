
import { theExtensionManager } from "../src/application/ExtensionManager";
import { externalExtensions, getExtensionEntryKey } from './externalExtensions';


var ext = BUILD_FLAG__MINIFIED_BUILD ? 'min.js' : 'js';

// Register them all
externalExtensions.forEach(function (ee) {

  var key = getExtensionEntryKey(ee);
  var filePath = "extensions/".concat(key, "/").concat(key, ".").concat(ext);
  var dependencies = ee.dependencies;
  ee.ids.forEach(function (id) {
    theExtensionManager.registerExternalExtension(id, filePath, dependencies);
  });
});


if (BUILD_FLAG__DIFF_TOOL) {
  // Not available in externalExtensions.js
  theExtensionManager.registerExternalExtension('Autodesk.DiffTool', "extensions/DiffTool/DiffTool.".concat(ext));
}