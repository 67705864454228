var _MeasurementTypesToAn;function _defineProperty(obj, key, value) {if (key in obj) {Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });} else {obj[key] = value;}return obj;}
//TODO_TS: Rename this to singular
export var MeasurementTypes = {
  MEASUREMENT_DISTANCE: 1, // Measurement from point to point, not matter what geometry it is.
  MEASUREMENT_ANGLE: 2,
  MEASUREMENT_AREA: 3,
  CALIBRATION: 4,
  MEASUREMENT_CALLOUT: 5,
  MEASUREMENT_LOCATION: 6,
  MEASUREMENT_ARC: 7 };


export var MeasurementTypesToAnalytics = (_MeasurementTypesToAn = {}, _defineProperty(_MeasurementTypesToAn,
MeasurementTypes.MEASUREMENT_DISTANCE, 'Distance'), _defineProperty(_MeasurementTypesToAn,
MeasurementTypes.MEASUREMENT_ANGLE, 'Angle'), _defineProperty(_MeasurementTypesToAn,
MeasurementTypes.MEASUREMENT_AREA, 'Area'), _defineProperty(_MeasurementTypesToAn,
MeasurementTypes.CALIBRATION, 'Calibration'), _defineProperty(_MeasurementTypesToAn,
MeasurementTypes.MEASUREMENT_CALLOUT, 'Callout'), _defineProperty(_MeasurementTypesToAn,
MeasurementTypes.MEASUREMENT_LOCATION, 'Location'), _defineProperty(_MeasurementTypesToAn,
MeasurementTypes.MEASUREMENT_ARC, 'Arc'), _MeasurementTypesToAn);