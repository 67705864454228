import { Animation } from '../Animation';

export function VisibilityAnimation(data, nodeId, animator) {var _this = this;
  Animation.call(this, {}, data, animator);

  this.fragPointers = [];
  var fragPointer;
  // Cache these so we don't iterate each frame
  this.nodeChildren = [];
  this.nodeFragments = [];

  var instanceTree = this.viewer.model.getData().instanceTree;
  instanceTree.enumNodeChildren(nodeId, function (dbId) {
    _this.nodeChildren.push(dbId);
  }, true);
  instanceTree.enumNodeFragments(data.id, function (fragId) {
    _this.nodeFragments.push(fragId);
    fragPointer = _this.viewer.getFragmentProxy(_this.viewer.model, fragId);
    if (fragPointer) {
      _this.fragPointers.push(fragPointer);
      //Need to clone the material as it can be shared between many objects
      //and we need to modify it for this object specifically
      fragPointer.setMaterial(_this.viewer.matman().cloneMaterial(fragPointer.getMaterial(), _this.viewer.model));
    }
  }, true);


  this.nodeId = nodeId;
  this.epsilon = 0.1;
}

VisibilityAnimation.prototype = Object.create(Animation.prototype);
VisibilityAnimation.prototype.constructor = VisibilityAnimation;
VisibilityAnimation.prototype.keyTypes = ["vis", "opa"];
VisibilityAnimation.prototype.defaultKey = { viz: 1, opa: 1 };

VisibilityAnimation.prototype.getPrevAndNextKeys = function (objHeirarcy, keyType) {
  var prevKey = this.data.hierarchy[objHeirarcy].keys[0];
  var nextKey = this.getNextKeyWith(keyType, objHeirarcy, 1);

  while (nextKey.time < this.currentTime && nextKey.index > prevKey.index) {
    prevKey = nextKey;
    nextKey = this.getNextKeyWith(keyType, objHeirarcy, nextKey.index + 1);
  }

  return {
    prevKey: prevKey,
    nextKey: nextKey };

};

VisibilityAnimation.prototype.update = function () {
  return function (delta) {
    if (this.isPlaying === false) return;

    this.currentTime += delta * this.timeScale;

    this.resetIfLooped();

    // bail out if out of range when playing
    if (this.isPlayingOutOfRange()) return;

    for (var h = 0, hl = this.hierarchy.length; h < hl; h++) {
      var object = this.hierarchy[h];
      var animationCache = object.animationCache[this.data.name];

      var prevVisKey = animationCache.prevKey['vis'];
      var nextVisKey = animationCache.prevKey['vis'];

      var prevOpaKey = animationCache.prevKey['opa'];
      var nextOpaKey = animationCache.prevKey['opa'];

      if (nextVisKey.time <= this.currentTime || prevVisKey.time >= this.currentTime) {
        var _this$getPrevAndNextK = this.getPrevAndNextKeys(h, 'vis'),prevKey = _this$getPrevAndNextK.prevKey,nextKey = _this$getPrevAndNextK.nextKey;
        prevVisKey = prevKey;
        nextVisKey = nextKey;
        animationCache.prevKey['vis'] = prevVisKey;
        animationCache.nextKey['vis'] = nextVisKey;
      }

      if (nextOpaKey.time <= this.currentTime || prevOpaKey.time >= this.currentTime) {
        var _this$getPrevAndNextK2 = this.getPrevAndNextKeys(h, 'opa'),_prevKey = _this$getPrevAndNextK2.prevKey,_nextKey = _this$getPrevAndNextK2.nextKey;
        prevOpaKey = _prevKey;
        nextOpaKey = _nextKey;
        animationCache.prevKey['opa'] = prevOpaKey;
        animationCache.nextKey['opa'] = nextOpaKey;
      }

      // Visibility

      var prevValue = prevVisKey['vis'];
      var nextValue = nextVisKey['vis'];

      if (prevValue !== undefined && nextValue !== undefined && prevVisKey.time !== nextVisKey.time) {
        var isNextKey = Math.abs(this.currentTime - nextVisKey.time) < this.epsilon;
        var vis = isNextKey ? nextValue : prevValue;
        this.viewer.visibilityManager.setNodeOff(this.nodeId, !vis, this.viewer.model, this.nodeChildren, this.nodeFragments);
      }

      // Opacity

      prevValue = prevOpaKey['opa'];
      nextValue = nextOpaKey['opa'];

      // Handle undefined opacity
      // Initially opacity is default value of 1, but when scrubbing, the value might have changed
      if (prevValue === undefined && prevVisKey['vis'] === 1) {
        // Restore opacity to default value only if the object was visible initially
        prevValue = 1;
      }

      if (prevValue !== undefined && nextValue !== undefined && prevOpaKey.time !== nextOpaKey.time) {
        var scale = (this.currentTime - prevOpaKey.time) / (nextOpaKey.time - prevOpaKey.time);
        if (scale < 0) scale = 0;
        if (scale > 1) scale = 1;
        var opacity = prevValue + (nextValue - prevValue) * scale;

        for (var fp = 0; fp < this.fragPointers.length; ++fp) {
          var material = this.fragPointers[fp].getMaterial();
          material.transparent = opacity !== 1;
          material.opacity = opacity;
        }

        if (opacity > 0) {
          this.viewer.visibilityManager.setNodeOff(this.nodeId, false, this.viewer.model, this.nodeChildren, this.nodeFragments);
        }
      }
    }
  };
}();