function _typeof(obj) {"@babel/helpers - typeof";return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {return typeof obj;} : function (obj) {return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;}, _typeof(obj);} /**
 * Sets obj[funName] = fun, only if (typeof obj === 'object' || typeof obj === 'function') && !obj.hasOwnProperty(funName).
 * 
 * @param {any} obj The object on which to define the function.
 * @param {string} funName The name of the function being defined.
 * @param {Function} fun The function definition.
 */
export var defineFunctionIfMissing = function defineFunctionIfMissing(obj, funName, fun) {
  if ((_typeof(obj) === 'object' || typeof obj === 'function') && !Object.prototype.hasOwnProperty.call(obj, funName)) {
    obj[funName] = fun;
  }
};

/**
 * Calls Object.defineProperty(obj, prop, descriptor), only if typeof obj === 'object'.
 * 
 * This is especially useful if obj can be null or undefined.
 * 
 * @param {any} obj The object on which to define the property.
 * @param {PropertyKey} prop The name or Symbol of the property to be defined or modified.
 * @param {PropertyDescriptor} descriptor The descriptor for the property being defined or modified.
 */
export var definePropertySafe = function definePropertySafe(obj, prop, descriptor) {
  if (_typeof(obj) === 'object') {
    Object.defineProperty(obj, prop, descriptor);
  }
};